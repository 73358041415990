.services{
    margin: 80px  auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
}
.service img{
    width:100%;
    border-radius: 10px;
    display: block;
    margin-left: 0px;
    height: 55vh;
   
}
.service{
    flex-basis: 31%;
    position: relative;
    
}
.caption{
    border-radius: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:rgba(1, 1, 1, 0.307);
    display: flex;
    align-items: center;
    justify-content:space-between;
    flex-direction: column;
    color:white;
    cursor: pointer;
    padding: 12px;
    opacity: 10;
    


}
.caption img{
    width: 60px;
    height: 60px;
    
    
    
}

.caption p{
    color: rgb(1, 23, 57);
    font-size: 17px;
    line-height: 1.2;
    font-weight: 300;
    margin-top: 9px;
    font-family:"arial";
    display: flex;
    background-color: rgba(234, 240, 246, 0.829);
    padding: 13px;
    border-radius: 5px;
    height: 200px;
    text-align: left;
}

.caption h1{
    font-size: 15px;
    padding-top: 0px;
    /*background-color: rgba(120, 120, 140, 0.395);*/
    color: #fff6f6;
    margin-top: -2px;
 
}

.services1{
    margin: 80px  auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
    flex-direction: row;

}
.services2{
    margin: 80px  auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
    flex-direction: column;
    
}
.pair{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2px;
    text-align: center;
    
    
}
.caption::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 2.8px;
    background: linear-gradient(to right, #037398, #ff7e5f,#fdfc47);
    mask:
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask:
    linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    opacity: 0;
    transition: 0.5s;
  }
.caption:hover{
    
    transform: scale(1.01);
    
}
.caption:hover::before{
    
  opacity: 1;
    
}
