@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
body{
  background: linear-gradient(to right,#f5fffa,#f3f5f5c9);
}
.container{
  padding-left: 6%;
  padding-right: 6%;
}