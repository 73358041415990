.contact{
    margin: 80px auto;
    max-width: 90%;
    display: flex;
    align-content: center;
    justify-content: space-between;
}
.contact-col{
    flex-basis: 48%;
    color:#282525;
}
.contact-col h3{
    color: #000f38;
    font-weight:400 ;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.contact-col h3 img{
    width:30px;
    margin-left: 10px;
}
.contact-col p{
    max-width: 450px;
    line-height: 1.3;
    color: #302e2e;
    font-weight: 400;
    text-align: justify;
    
}
.contact-col ul li{
    display: flex;
    align-items: center;
    margin: 20px 0;

}
.contact-col ul li img{
    width: 30px;
    margin-right: 12px;
}
.contact form input,.contact form textarea{
    display: block;
    width: 100%;
    background-color: #cfdef3;
    padding: 15px ;
    border: 0.5px solid rgba(71, 99, 99, 0.277);
    outline: 0;
    margin-bottom: 15px;
    margin-top: 5px;
    resize: none;
    border-radius: 5px;
}
 
.contact-col span{
    display: block;
    margin: 20px 0;
}
.btn{
    padding: 10px 25px;
    background:linear-gradient(to right,#266dd8,#1ecdd6);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.07);
    text-align: center;

}
.btn img{
    width:25px;
    height:25px;
    margin-left: 7px;
    width: 0px;
    transition: 0.4s;
}

.btn:hover img{
    width: 23px;
    overflow: hidden;
}
