nav{
    width: 100%;
    background: rgba(6, 6, 6, 0.941);
    color: white;
    padding: 4px 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}

.logo{
    width: 16%;
    height: 60%;
    border-radius: 10px;
    padding:5.5px;
}

nav ul li{
    display: inline-block;
    list-style: none;
    margin: 5px 20px;
    font-size: 16px;
    color:white;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 5px;
}
.unique{
    background:linear-gradient(to bottom, #e0eaf3,#cfdef3);
    color: rgb(51, 49, 49);
    border-radius: 19px;
    padding:10px 20px 10px 20px;
    
}
 .hover li:not(.unique):hover{
    background:linear-gradient(to bottom, #e0eaf3,#cfdef3) ;
    padding: 10px;
    border-radius: 19px;
    color: rgb(4, 30, 60);
}
.dark-nav{
    background-color: #1d1d5ffc;
}


