.product{
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content:space-between ;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}
.product-left{
    flex-basis: 40%;
}
.product-right{
    flex-basis: 56%;
}
.product-img{
    width:100% ;
    border-radius: 10px;
   
}
.product-right p{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: rgb(27, 27, 28);
    padding-top: 10px;
    text-align: justify;
    word-spacing: -0.02em;
    letter-spacing: 0.01rem;


    
}
.product-right h1{
    font-size: 26px;
    padding-bottom: 5px;
    padding-top: 8px;
    color: #2a4cbb;
    line-height: 29px;
}
.overall-bg{
    background:linear-gradient(to right,#E0EAF3,#CFDEF3);
    width: 100%;
    height: 100%;
    padding: 15px;
    border-radius: 10px;

}