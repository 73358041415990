.footer{
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1.5px solid #1b1c52;
    padding: 14px 0;
}
.footer ul li{
    list-style: none;
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
    font-size: 14px;
}
.footer p{
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 14px;
    
}
.footer img{
    width:15px;
    height:15px;
    margin-right: 5px;
}