.about{
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #2a4cbb;
}
.about-left{
    flex-basis: 40%;
    position: relative;
}
.about-right{
    flex-basis: 58%;
}
.about-img{
    width: 100%;
    border-radius: 10px;
}
.play-icon{
    width: 60px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.about-right p{
    color: #000;
    font-size: 19px;
    font-weight: 400;
    text-align: justify;
    padding-top: 5px;
    padding-bottom: 6px;
}
.unique1{
  color: #2a4cbb;
}