.testimonials{
    margin: 80px auto;
    padding: 0 80px;
    position: relative;
}
.next-btn,.back-btn{
    position: absolute;
    top:50%;
    right: 0;
    transform: translateY(-50%);
    width: 50px;
    cursor: pointer;
}
.back-btn{
    right: auto;
    left: 0;
}
.slide{
  overflow: hidden;  
}
.slide ul{
    display: flex;
    width: 200%;
    overflow-x: hidden;
    transition: 0.5s;
}
.slide ul li{
    list-style: none;
    width: 50%;
    padding: 20px;
}
.sliding{
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    padding: 30px;
    border-radius: 10px;
    color: #565151;
    background:linear-gradient(to right,#E0EAF3,#00B4DB);
    height: 200px;
   
}
.sliding img{
    width: 60px;
    margin-right: 10px;
    border-radius: 50%;
    border:  2.5px solid #31d1cebf ;
}
.user-info{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 15px;
}
.slide  p{
    color: #2f2d2d;
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
}
.sliding .user-info h2{
    color: #000;
    
}
