.tittle {
    text-align: center;
    text-transform: uppercase;
    margin: 60px 0 30px;
}
.tittle h2{
    font-size: 32px;
    color: #3c3c3c;
    margin-top: 5px;
    text-transform: none;

}
.tittle p{
color: rgba(40, 40, 111, 0.853);
font-weight: 600;
font-size: 20px;
}