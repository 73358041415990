.carousel {
    position: relative;
    width: 100%;
    margin: auto;
}

.carousel-slide{
    position: relative;
}
  .carousel-slide img{
    height: 100vh;
    background-size: cover;
    padding-top: 98px;

}
.carousel-text {
    position: absolute;
    left: 50px;
    bottom: 50px;
    color: rgba(5, 61, 244, 0.859);
    padding: 10px;
    text-align: left;
    line-height: 15px;
  }
  .carousel-text h1{
    font-size: 85px;
    line-height: 67px;
    padding-bottom: 15px;
    text-align: center;
  }
  .carousel .carousel-text p{
    font-size: 20px;
    line-height: 27px;
    color: rgb(251, 248, 248);
    font-weight: 550;
    text-align: center;

  }
  .uni2{
    font-size: 85px;
    background-image: linear-gradient(to right, #142ad1, #0f357f,rgba(14, 13, 15, 0.947));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }
  .uni3{
    font-size: 85px;
    background-image:linear-gradient(to top, #f3e9e9, rgb(251, 244, 244), rgb(5, 0, 0), rgb(2, 10, 252), rgb(6, 105, 235));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .uni4{
    font-size: 85px;
    background-image: linear-gradient(to top, #020a0a, #037ffc,rgb(0, 0, 0));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }
  .slide2 .carousel-text{
    transform: translate(-20%,-50%);
    top:65%;
    left:70%;
  }
  .slide1 .carousel-text{
    transform: translate(-20%,-80%);
    top:65%;
    left:60%;
  }
  .slide3 .carousel-text{
    transform: translate(10%,-40%);
    top:65%;
    left:60%;
  }